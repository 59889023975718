.generalriddle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(76, 105, 143);
  height: 100%;
  padding-bottom: 50px;
}


.cluediv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 50px;
}

.clue {
  object-fit: contain;
  height: 800px;
  border: white solid 1px;
}

.riddleformdiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  margin-top: 50px;
  margin-bottom: 15px;
  margin-left: -500px;
  margin-right: 100px;
}

.riddleform_in {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 100px;
}

.riddleinput {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 6em;
  height: 100px;
  font-size: 90px;
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.riddlesubmit {
  width: 250px;
  height: 100px;
  background-color: transparent;
  color: white;
  border: white solid 3px;
  border-radius: 15px;
}

.computer_icon {
  position: absolute;
  top: 300px;
  left: 250px;
  width: 100px;
  height: 100px;
  border: white solid 3px;
  padding: 20px;
  border-radius: 50%;
  rotate: (25deg);
}


@media (max-width: 1025px) {
  .clue {
    height: 400px;
  }

  .riddleform_in {
    flex-direction: column;
    gap: 50px;
  }
}