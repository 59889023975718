body,
html {
  background-color: black;
  font-family: "IBM Plex Mono", monospace;
  height: 100%;
}

#root {
  height: 100%;
}

.root2 {
  height: 100%;
}

.general {
  background-color: black;
  color: white;
  height: 85%;
}

.riddlediv {
  height: 100%;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border-bottom: white solid 2px;
  .nav {
    display: flex;
    width: 50%;
    justify-content: space-between;
    .navlink {
      text-decoration: none;
      color: white;
      border: white solid 2px;
      padding: 5px;
      border-radius: 10px;
      padding-left: 15px;
      padding-right: 15px;
    }
    .navreset {
      margin-left: 150px;
      margin-right: -400px;
      background-color: rgb(122, 15, 15);
      color: white;
      padding: 10px;
      border-radius: 7px;
      border: none;
      font-family: "IBM Plex Mono", monospace;
    }
  }
}

.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.inactive {
  display: none;
}

.active {
  display: block;
}

.glitch {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  #glitchvideo {
    width: 100%;
    height: 100%;
  }
}

//////LIBRARY///////////

.library_gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin: 30px;
  .library_card_out {
    border: 2px solid #b78846;
    .library_card {
      width: 400px;
      height: 300px;
      border: 2px solid #b78846;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px;
      padding: 10px;

      img {
        width: 400px;
        height: auto;
      }
      video {
        width: 400px;
        height: 300px;
      }
    }
  }
}

@media (max-width: 1025px) {
  .header {
    .nav {
      .navreset {
        margin: 0px;
      }
    }
  }
}
